// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url('../images/icons/sprite.png');
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;
}

.sprite {

	&.back-top {
		width: 50px;
		height: 50px;
		background-position: 0 0;
	}

	&.title-decoration-black,
	&.title-decoration-red,
	&.title-decoration-white {
		width: 150px;
		height: 20px;
	}

	&.title-decoration-red {
		background-position: 0 -50px;
	}
	&.title-decoration-black {
		background-position: 0 -70px;
	}
	&.title-decoration-white {
		background-position: 0 -90px;
	}

	&.arrow-bottom-black,
	&.arrow-right-white {
		width: 10px;
		height: 10px;
	}

	&.arrow-right-white {
		background-position: -50px 0;
		height: 12px;
	}

	&.arrow-bottom-black {
		background-position: -60px 0;
	}

	&.calendar {
		width: 20px;
		height: 20px;
		background-position: -70px 0;
	}

	&.facebook-white,
	&.instagram-white {
		width: 40px;
		height: 40px;
	}

	&.instagram-white {
		background-position: -90px 0;
	}

	&.facebook-white {
		background-position: -130px 0;
	}

	&.resa-car-blue,
	&.resa-car-white,
	&.resa-moto-blue,
	&.resa-moto-white {
		width: 40px;
		height: 20px;
	}

	&.resa-moto-white {
		background-position: -170px 0;
	}

	&.resa-car-white {
		background-position: -210px 0;
	}

	&.resa-moto-blue {
		background-position: -170px -20px;
	}

	&.resa-car-blue {
		background-position: -210px -20px;
	}

	&.icon-1,
	&.icon-2,
	&.icon-3,
	&.icon-4,
	&.icon-5,
	&.icon-6 {
		width: 80px;
		height: 80px;
	}

	&.icon-1 {
		background-position: -250px 0;
	}
	&.icon-2 {
		background-position: -330px 0;
	}
	&.icon-3 {
		background-position: -410px 0;
	}
	&.icon-4 {
		background-position: -490px 0;
	}
	&.icon-5 {
		background-position: -570px 0;
	}
	&.icon-6 {
		background-position: -650px 0;
	}

}


.sprite.vehicle-boite-a,
.sprite.vehicle-boite-m,
.sprite.vehicle-clim,
.sprite.vehicle-motorisation-d,
.sprite.vehicle-motorisation-e,
.sprite.vehicle-passager-2,
.sprite.vehicle-passager-3,
.sprite.vehicle-passager-4,
.sprite.vehicle-passager-5,
.sprite.vehicle-passager-6,
.sprite.vehicle-passager-7,
.sprite.vehicle-passager-8,
.sprite.vehicle-passager-9,
.sprite.vehicle-porte-2,
.sprite.vehicle-porte-3,
.sprite.vehicle-porte-4,
.sprite.vehicle-porte-5,
.sprite.vehicle-radio,
.sprite.vehicle-bagage {
	width: 20px;
	height: 20px;
}

.sprite.vehicle-clim {
	background-position: 0 -120px;
}

.sprite.vehicle-radio {
	background-position: -20px -120px;
}

.sprite.vehicle-passager-2 {
	background-position: -40px -120px;
}

.sprite.vehicle-passager-3 {
	background-position: -60px -120px;
}

.sprite.vehicle-passager-4 {
	background-position: -80px -120px;
}

.sprite.vehicle-passager-5 {
	background-position: -100px -120px;
}

.sprite.vehicle-passager-6 {
	background-position: -120px -120px;
}

.sprite.vehicle-passager-7 {
	background-position: -140px -120px;
}

.sprite.vehicle-passager-8 {
	background-position: -160px -120px;
}

.sprite.vehicle-passager-9 {
	background-position: -180px -120px;
}

.sprite.vehicle-porte-2 {
	background-position: -200px -120px;
}

.sprite.vehicle-porte-3 {
	background-position: -220px -120px;
}

.sprite.vehicle-porte-4 {
	background-position: -240px -120px;
}

.sprite.vehicle-porte-5 {
	background-position: -260px -120px;
}

.sprite.vehicle-boite-m {
	background-position: -280px -120px;
}

.sprite.vehicle-boite-a {
	background-position: -300px -120px;
}

.sprite.vehicle-motorisation-d {
	background-position: -320px -120px;
}

.sprite.vehicle-motorisation-e {
	background-position: -340px -120px;
}

.sprite.vehicle-bagage {
	background-position: -360px -120px;
}

@font-face {
  font-family: 'noteworthybold';
  src: url('../fonts/fontsfree-net-noteworthy-bold-webfont.woff2') format('woff2'), url('../fonts/fontsfree-net-noteworthy-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'museo300';
  src: url('../fonts/museo_300-webfont.woff2') format('woff2'), url('../fonts/museo_300-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'museo500';
  src: url('../fonts/museo_500-webfont.woff2') format('woff2'), url('../fonts/museo_500-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


// Font family
$font-stack: 'museo300', sans-serif; // Html

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity .1s ease-in-out;
}
// Title :: soft reset

p {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-bottom: 0;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {
  font-size: 37px;
  letter-spacing: .05em;
  font-family: 'noteworthybold', cursive;
  text-align: center;

  &:after {
    content: '';
    display: block !important;
    margin: 2px auto 0;
  }

  &.black {
    &:after {
      @extend .sprite;
      @extend .title-decoration-black;
    }
  }

  &.red {
    &:after {
      @extend .sprite;
      @extend .title-decoration-red;
    }
  }
}

.h-like-b {
  line-height: 1.3em;
  letter-spacing: .05em;
  font-size: 32px;
  font-family: 'noteworthybold', cursive;
}


.h-like-c {
  font-family: 'museo500', sans-serif;
  font-size: 20px;
}
@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: 30px;
  }
  .h-like-b {
    font-size: 28px;
  }
}
@include media-breakpoint-down(xs) {
  .h-like-b {
    font-size: 24px;
  }

}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a {}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {}
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}

// Footer

#footer {
	position: relative;
	.footer-leaf {
		position: absolute;
		top: -90px;
		right: 50%;
		margin-right: -600px;
		z-index: 1;
		@include media-breakpoint-down(md) {
			right: auto;
			left: 50px;
			top: -60px;
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.info-list a,
	.link {
		&:hover {
			text-decoration: underline;
		}
	}
	.social-link {
		display: block;
		transition: all .2s ease-in-out;
		&:hover {
			transform: translateY(5px);
		}
	}
	&:before {
		content: '';
		display: block;
		width: 2500px;
		height: 96px;
		background: url('../images/illustrations/bg-blue-wave.png') no-repeat;
		margin-left: -200px;
		position: relative;
		z-index: 2;
    top: 4px;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.footer-top {
		position: relative;
		z-index: 2;
		background-color: $bl-b;
		color: $wh-a;
		.container {
			padding: 75px 15px 75px;
			position: relative;
			.tel {
				display: block;
				position: absolute;
				bottom: 0;
				left: -10px;
				width: 180px;
				text-align: center;
				transition: all .1s ease-in-out;
				padding-left: 10px;
				&:hover {
					transform: translateY(-5px);
				}
				span {
					font-weight: 700;
					color: $wh-a;
					font-size: 16px;
					position: relative;
					z-index: 2;
					top: -15px;
				}

				&:after {
					content: '';
					z-index: 1;
					position: absolute;
					width: 100%;
					top: -40px;
					left: 10px;
					bottom: 0;
					background-color: $rd-a;
					padding: 40px 30px;
					border-radius: 20px 20px 0 0;
					transform: rotate(6deg);
				}
				@include media-breakpoint-down(sm) {
					left: 15px;
				}
			}
		}
	}
	.footer-bottom {
		position: relative;
		z-index: 2;
		background-color: $bl-c;
		color: $wh-a;
		font-size: 11px;
		padding: 10px 0;
    img{
      width: auto;
      height: 30px;
    }
		@include media-breakpoint-down(sm) {
			padding: 15px 0;
		}
	}
	.title {
		font-size: 15px;
		font-family: 'museo500', sans-serif;
		text-transform: uppercase;
	}
	.info-list {
		font-size: 13px;
		line-height: 1.6em;
	}
	.nav-list {
		.item {
			display: block;
			margin-top: 10px;
		}
		.link {
			display: block;
			font-size: 13px;
			font-family: 'museo300', sans-serif;
		}
	}
	@include media-breakpoint-down(md) {
		.footer-top {
			.container {
				padding: 50px 15px 75px;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.footer-top {
			.container {
				padding: 30px 15px 100px;
			}
		}
	}
	@include media-breakpoint-down(sm) {}
}

// Body
body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}
html {
	overflow-x: hidden;
}

#main {
	position: relative;
	z-index: 2;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: .65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url('../images/icons/sprite-popup.png') !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all .2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: .65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url('../images/icons/sprite-popup.png') !important;
	transition: all .2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all .2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all .2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: .8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: $bk-a;
		border-radius: px(18);
		transition: all .2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $bk-a;
	}

	button:focus,
	button:hover {
		background-color: $bk-a;
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 500px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Slick :: Home

#slick-wp {}

#slick {}

#slick-wp {
	.item {}

	#slick-arrows {
		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
	// Animation classes
	// .slick-slide .content							{ opacity: 0; transform: translateY(-30px) scale(1); transition: all 0.2s ease-in-out 0.5s;}
	// .slick-slide.animation .content					{ opacity: 1; transform: translateY(0) scale(1); }
}

.home-bloc-footer {
	position: relative;
	top: 50px;
	z-index: 3;
	padding-bottom: 20px;
	@include media-breakpoint-down(lg) {
		top: auto;
		padding: 50px 0;
	}
	@include media-breakpoint-down(md) {
		top: auto;
		padding: 30px 0;
	}
}

.home-bloc-why {
	position: relative;
	.decoration-bubble,
	.decoration-leaf {
		position: absolute;
		z-index: 2;
		left: 50%;
	}
	.decoration-leaf {
		top: -50px;
		margin-left: -675px;
	}
	.decoration-bubble {
		top: -70px;
		margin-left: 400px;
	}
	@include media-breakpoint-down(lg) {
		.decoration-leaf {
			margin-left: -575px;
		}
		.decoration-bubble {
			margin-left: 300px;
		}
	}
	@include media-breakpoint-down(md) {
		.decoration-leaf {
			margin-left: -475px;
		}
		.decoration-bubble {
			margin-left: 200px;
		}
	}
	@include media-breakpoint-down(sm) {
		.decoration-bubble,
		.decoration-leaf {
			display: none;
		}
	}

	&:before {
		content: '';
		display: block;
		width: 2500px;
		height: 96px;
		background: url('../images/illustrations/bg-yellow-wave.png') no-repeat;
		margin-left: -200px;
		position: relative;
		z-index: 2;
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.title {
		font-size: 16px;
		margin-top: 10px;
		line-height: 1.2em;
		strong {
			display: block;
			margin-top: 5px;
			font-family: 'museo500', sans-serif;
		}
	}
}

.blog-item {
	height: 100%;
	.link {
		height: 100%;
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
		.img {
			width: 100%;
			border-radius: 15px;
			transition: opacity .2s ease-in-out;
		}
		.title {
			margin-top: 20px;
			margin-bottom: 10px;
			font-family: 'noteworthybold', cursive;
			line-height: 1.3em;
			font-weight: bold;
			font-size: 22px;
			letter-spacing: .05em;
			transition: color .2s ease-in-out;
		}
		&:hover {
			.title {
				color: $rd-a;
			}
			.img {
				opacity: .8;
			}
			.btn-a {
				background-color: $wh-a;
				color: $rd-a !important;
			}
		}
		@include media-breakpoint-down(md) {
			.title {
				font-size: 18px;
			}
		}
		@include media-breakpoint-down(sm) {
			.title {
				font-size: 24px;
			}
		}
	}
}

.home-top {
	position: relative;
	margin-top: -90px;
	.header {
		transform: rotate(-10deg);
		position: relative;
		*[class^="leaf"] {
			position: absolute;
			z-index: 1;
			&.leaf-top {
				right: 60px;
				top: -75px;
			}
			&.leaf-bottom {
				left: -120px;
				bottom: -100px;
				transform: rotate(180deg);
			}
		}
		.line {
			position: relative;
			z-index: 2;
			font-family: 'noteworthybold', cursive;
			font-size: 32px;
			white-space: nowrap;
			line-height: 1em;
			padding: 10px 20px;
			display: inline-block;
		}
	}
	#slick-home {
		position: relative;
		z-index: 2;
		.line {
			padding: 8px 20px;
		}
		.title {
			font-size: 20px;
		}
	}
	@include media-breakpoint-down(lg) {
		margin-top: 0;
		.header {
			.line {
				font-size: 30px;
			}
		}
		.img-car {
			width: 600px;
		}
	}
	@include media-breakpoint-down(md) {
		margin-left: -50px;

		.header {
			.line {
				font-size: 26px;
			}
			#slick-home {
				.title {
					font-size: 18px;
				}
			}

		}
	}
	@include media-breakpoint-down(sm) {
		margin-left: -25px;
		padding-top: 50px;

		.img-car {
			width: 400px;
		}

		.header {
			.line {
				font-size: 20px;
			}
			#slick-home {
				.title {
					font-size: 16px;
				}
			}

		}
	}
	@include media-breakpoint-down(xs) {
		margin-left: 0;
		padding-top: 50px;
		padding-bottom: 50px;
		.img-car {
			position: absolute;
			z-index: 0;
			width: 300px;
			right: -150px;
			top: -70px;
		}
		.col-sm-9 {
			position: relative;
			z-index: 2;
		}
		.header {
			width: 400px;
			margin: 0 auto;
			*[class^="leaf"] {
				width: 125px;
				&.leaf-top {
					right: 70px;
					top: -50px;
				}
				&.leaf-bottom {
					left: -100px;
					bottom: -80px;
				}
			}
		}
	}
}

.resa-form {

	position: relative;
	z-index: 2;

	.bg-bl-b {
		position: relative;
		z-index: 3;
	}

	.resa-form-radio {
		display: flex;
		position: relative;
		top: 1px;
		.checkboxradio-wp {
			> input[type=radio]:checked + label {
				background-color: $bl-b;
				.resa-moto-blue {
					@extend .resa-moto-white;
				}
				.resa-car-blue {
					@extend .resa-car-white;
				}
			}
			label {
				text-align: center;
				border-radius: 10px 10px 0 0;
				display: inline-block;
				background-color: $bl-a;
				background-image: none;
				padding: 10px 15px 12.5px;
				margin-bottom: 0;
			}
		}
	}

	&.inner-page {
		.bg-bl-b {
			background-color: $bl-c;
		}
		.resa-form-radio {
			label {
				margin-right: 10px;
				border-radius: 15px;
			}
		}
	}
}

// Header page
#header-page {
	background-color: $bl-b;
	color: $wh-a;
	text-align: center;
	position: relative;
	padding: px(35) 0;
	box-shadow: 0 5px 10px -6px inset rgba(0, 0, 0, .2);
	&.except-resa {
		padding: 0;
	}

	.header-leaf {
		position: absolute;
		&.right {
			right: -120px;
			bottom: -250px;
			transform: rotate(0deg) translateY(0px);
		}
		&.left {
			left: -120px;
			bottom: -550px;
			transform: rotate(180deg) translateY(0px);
		}
	}

	.container {
		position: relative;
		z-index: 2;
	}
	.title {
		font-family: 'noteworthybold', cursive;
		font-size: px(42);
		font-weight: 600;
		line-height: 1.2em;

		&:after {
			content: '';
			display: block !important;
			margin: 10px auto 0;
			@extend .sprite;
			@extend .title-decoration-white;
		}

	}
	@include media-breakpoint-down(lg) {
		.title {
			font-size: px(38);
		}
	}
	@include media-breakpoint-down(md) {
		padding: px(30) 0;
		&:after {
			display: none;
		}
		.title {
			font-size: px(35);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: px(25) 0;
		.header-leaf {
			display: none;
		}
		.title {
			font-size: px(32);
		}
	}
	@include media-breakpoint-down(xs) {
		padding: px(15) 0;
		.btn-a {
			padding: 10px 20px;
			font-size: 13px;
		}
		.title {
			font-size: px(24);
		}
	}
}

.contact-list {
	list-style: disc;
	padding-left: 15px;
}

// Step 3
.step-3-input {
	padding: px(10) px(15);
	border-radius: 8px;
	background-color: tint($gy-a, 60%);
	box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
	border: 1px solid $gy-a;

	.dropdown-toggle {
		border: 0 !important;
		.filter-option-inner-inner {
			text-overflow: ellipsis !important;
		}
	}

	label {
		text-transform: uppercase;
		font-family: 'museo500', sans-serif;
		font-size: px(12);
		color: $bl-c;
	}

	input,
	textarea {
		outline: none !important;
		box-shadow: none !important;
		border: none;
	}
}

// Blog :: Category navigation

.nav-category {
	border: 1px solid $gy-a;
	padding: px(20) px(30);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
	background-color: $wh-a;
	border-radius: 8px;

	.title-cat {
		font-size: px(13);
		padding-bottom: 6px;
		color: $rd-a;
		margin-bottom: px(15);
		text-transform: uppercase;
		font-weight: 600;
		border-bottom: 1px solid shade($gy-a, 50%);
	}

	.item {
		margin-top: px(5);
	}

	.list .item:first-child {
		margin-top: 0;
	}

	.link {
		position: relative;
		font-weight: 400;
		font-size: px(14);
		color: shade($gy-a, 50%);
		display: block;
		padding-left: px(20);
	}

	.link:before {
		content: '';
		display: block;
		position: absolute;
		top: 10px;
		left: 0;
		width: 8px;
		height: 2px;
		background-color: shade($gy-a, 50%);
		transition: all .2s ease-in-out;
	}

	.link.active {
		color: $rd-a !important;
		font-weight: 600;
		font-family: 'museo500', sans-serif;
	}

	.link.active:before {
		border-color: $rd-a !important;
		background-color: $rd-a !important;
	}

	.link:focus,
	.link:hover {
		color: $rd-a;
	}

	.link:focus:before,
	.link:hover:before {
		background-color: $rd-a;
	}
	@include media-breakpoint-down(sm) {
		border: none;
		box-shadow: 0 0 20px rgba(0,0,0,0.1);
		padding-top: 20px;
		padding-bottom: 20px;
	}
}
// Blog :: Article list
.category-label {
	display: inline-block;
	color: $bl-b;
	margin-top: 10px;
	background-color: $wh-a;
	font-weight: 600;
	font-family: 'museo500', sans-serif;
	letter-spacing: .05em;
	margin-bottom: px(20);
	font-size: px(12);
	padding: 4px 6px;
	border-radius: 3px;
	@include media-breakpoint-down(sm) {
		margin-bottom: px(10);
	}
}

.item-blog {
	position: relative;
	height: 100%;
	display: block;
	padding: 0;
	overflow: hidden;
	background-color: $wh-a;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0,0,0,0.15);
	transition: all .2s ease-in-out;

	.img-wp {
		position: relative;
		overflow: hidden;
	}

	.img-wp:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.15);
		transition: all .2s ease-in-out;
	}

	.img-wp .img-fluid {
		transition: all .2s ease-in-out;
	}

	.content {
		padding: 30px;
	}

	.category-blog {
		position: absolute;
		top: 17px;
		z-index: 10;
		right: 20px;
	}

	.title-blog {
		font-size: px(18);
		font-weight: 700;
		display: block;
		color: $bl-c;
		margin-bottom: px(8);
		line-height: 1.1em;
	}

	.desc {
		margin-top: px(10);
		font-size: px(14);
		margin-bottom: 0;
		line-height: 1.4em;
	}

	.btn-blog {
		color: $rd-a;
		margin-top: 10px;
		font-weight: 600;
		text-align: right;
		display: block;
	}

	&:focus .img-wp .img-fluid,
	&:hover .img-wp .img-fluid {
		transform: scale(1.1);
	}

	&:focus .img-wp:after,
	&:hover .img-wp:after {
		background-color: rgba(0,0,0,0.1);
	}
	@include media-breakpoint-down(sm) {
		.title {
			font-size: px(20);
		}
	}
}

// Pagination
.pagination {
	justify-content: center;
	margin-top: 15px;

	.page-link {
		color: $rd-a;
		height: 32px;
	}

	.page-link.active {
		background-color: $rd-a !important;
		color: $wh-a !important;
		border-top-color: $rd-a;
		border-bottom-color: $rd-a;
		font-weight: 400;
	}

	.page-item.link-arrow .page-link {
		padding: px(6) px(12);
		background-color: tint($gy-a, 30%);
		color: $bk-a;
		transition: all .2s ease-in-out;
	}

	.page-item.link-arrow .page-link:focus,
	.page-item.link-arrow .page-link:hover {
		background-color: $rd-a;
		color: $wh-a;
	}

	.page-item .page-link:focus,
	.page-item .page-link:hover {
		background-color: tint($gy-a, 90%);
	}
}

.services-item {
	img {
		border-radius: 15px;
	}
	.title {
		margin-top: 24px;
		margin-bottom: 10px;
		font-family: 'noteworthybold', cursive;
		line-height: 1.3em;
		font-weight: bold;
		font-size: 22px;
		letter-spacing: .05em;
		transition: color .2s ease-in-out;
	}
	p {
		margin-bottom: 5px;
	}
}

// step 4
.resa-step-4 {
	position: relative;
	height: 100%;
	padding: 25px 30px;
	background-color: $wh-a;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	border-radius: 8px;

	.title-sub {
		color: $rd-a;
		text-transform: uppercase;
		font-size: px(16);
		font-family: 'museo500', sans-serif;
		font-weight: 600;
		border-bottom: 1px solid $rd-a;
		padding-bottom: 5px;
	}

	.list {
		margin-top: 20px;
	}

	.list li {
		display: block;
		position: relative;
		padding-left: 18px;
		font-size: px(14);
		line-height: 1.4em;
		word-break: break-word;
	}

	.list li:not(:last-child) {
		margin-bottom: 7px;
	}

	.list li:before {
		content: '';
		position: absolute;
		top: 9px;
		left: 0;
		width: 8px;
		height: 2px;
		background-color: $rd-a;
	}
}

.resa-car-wp {
	.resa-car.except-parc .column.price-wp .bloc {
		height: 100%;
	}

	.resa-car.except-parc .column.price-wp .bloc .btn-submit {
		padding-top: 17px;
	}

	.resa-car.except-parc .column.price-wp .total:before {
		top: 3px;
	}

	.resa-car.except-parc .column.price-wp .price-total small {
		font-weight: 400;
		font-size: 11px;
	}

	.availability {
		display: inline-block;
		background-color: $bl-a;
		color: $bl-c;
		padding: 4px 10px 3px;
		border-radius: 10px;
		line-height: 1em;
		margin-left: 5px;
		vertical-align: top;
		> * {
			display: inline-block;
			vertical-align: middle;
		}
		.txt {
			font-size: 11px;
			padding-left: 2px;
		}
		.img {
			font-size: 12px;
		}
	}

	.promo {
		background: $bl-b;
		width: 70px;
		height: 70px;
		position: absolute;
		top: -15px;
		right: -15px;
		z-index: 10;
		text-align: center;
		padding-top: 25px;
		span {
			color: $wh-a;
			position: relative;
			z-index: 11;
			font-family: 'museo500', sans-serif;
			font-size: 14px;
			transform: rotate(30deg);
			display: block;
			text-transform: uppercase;
		}
		&:after,
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 70px;
			width: 70px;
			background: $bl-b;
		}
		&:before {
			transform: rotate(30deg);
		}
		&:after {
			transform: rotate(60deg);
		}
	}

	.resa-car.unavailable:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		background-color: rgba(255,255,255,0.5);
		width: calc(100% - 350px);
		height: 100%;
	}

	.single-col {
		height: 100%;
		padding: 30px 50px;
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;

		.btn-submit {
			font-size: 14px !important;
			height: 70px !important;
		}
		@include media-breakpoint-down(xs) {
			padding: 20px;
		}
	}

	.resa-car .unavailable-date,
	.resa-car .unavailable-txt {
		color: shade($gy-a, 60%);
		line-height: 1.2em;
		text-align: center;
		font-size: px(13);
	}

	.resa-car .unavailable-date {
		font-weight: 600;
		margin-top: px(10);
		font-size: px(14);
		font-family: 'museo500', sans-serif;
	}

	.item {
		margin-bottom: 50px;
	}

	.resa-car {
		position: relative;
		display: flex;
		width: 100%;
		border: 2px solid shade($gy-a,5%);
		border-radius: 10px;
		background-color: $wh-a;
		transition: all .2s ease-in-out;

    &.rd-a{
      border-color: $rd-a;
    }
    &.ye-a{
      border-color: $ye-a;
    }
    &.gn-a {
      border-color: $gn-a;
    }
    &.bl-c {
      border-color: $bl-b;
    }
    &.bl-b {
      border-color: $bl-c;
    }

  }

	.resa-car:focus,
	.resa-car:hover {
		box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
	}

	.resa-car .column {
		padding: 30px;
	}

	.resa-car .column.img-wp {
		flex: none;
		position: relative;
		width: 325px;
		border-right: 1px solid shade($gy-a,5%);
		vertical-align: middle;
	}

	.resa-car .column.img-wp .img-fluid {
		width: 325px;
	}

	.resa-car .column.img-wp .galery-more {
		background-color: tint($gy-a,25%);
		font-size: px(24);
		width: 40px;
		height: 40px;
		padding-top: 7px;
		line-height: 1.1em;
		font-weight: 400;
		text-align: center;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 2;
		color: $rd-a;
	}

	.resa-car .column.price-wp {
		flex: none;
		border-radius: 0 10px 10px 0;
		text-align: center;
		width: 220px;
		background-color: tint($gy-a, 80%);
		border-left: 1px solid $gy-a;
		padding: 0;
		//display: flex;
		@include media-breakpoint-down(lg) {

			.bloc {
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}
	}

	.resa-car .column.price-wp .price {
		font-size: px(28);
		margin-right: 10px;
		font-weight: 600;
		color: $rd-a;
	}

	.resa-car .column.price-wp .day {
		display: inline-block;
		line-height: 1em;
		font-size: px(11);
		font-style: italic;
		font-weight: 400;
		color: $gy-a;
	}

	.resa-car .column.price-wp .total {
		position: relative;
		text-transform: uppercase;
		margin-top: 0;
		font-family: 'museo500', sans-serif;
		font-size: 15px;
		font-weight: 400;
		color: $rd-a;
	}

	.resa-car .column.price-wp .bloc.right .total {
		color: shade($gy-a, 40%);
	}

	.resa-car .column.price-wp .total:before {
		content: '';
		position: absolute;
		top: 9px;
		left: 0;
		margin-top: 0;
		// @extend .sprite;
		// @extend .arrow-select;
		transform: rotate(-90deg) scale(0.9);
	}

	.resa-car .column.price-wp .price-list {
		margin-bottom: 2px;

		.small {
			display: block;
			font-size: 11px;
			padding: 5px 10px;
			margin-top: 5px;
			line-height: 1em;
			background-color: shade($gy-a, 5%);
			border-radius: 20px;
			color: $rd-a;
		}
	}

	.label-cheap {
		border-radius: 10px 0 10px 0;
		position: absolute;
		top: 0;
		left: 0;
		background-color: $rd-a;
		color: #FFF;
		font-size: 11px;
		padding: 3px 9px;
	}

	.resa-car .column.price-wp .price-total {
		font-size: px(22);
		font-family: 'museo500', sans-serif;
		font-weight: 600;
	}

	.resa-car .column.price-wp .btn-submit {
		text-align: center;
		width: 100%;
		color: $wh-a;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 600;
		height: 50px;
		background-color: tint($bk-a, 30%);
		transition: all .2s ease-in-out;
		border-radius: 10px;
	}

	.resa-car .column.price-wp .btn-submit.orange {
		background-color: $rd-a;
	}

	.resa-car .column.price-wp .btn-submit .small {
		display: block;
		line-height: 1em;
		margin-top: 3px;
		text-align: center;
		font-size: px(9);
		font-style: italic;
		text-transform: none;
	}

	.resa-car .column.price-wp .btn-submit.orange:focus,
	.resa-car .column.price-wp .btn-submit.orange:hover {
		background-color: shade($rd-a, 10%) !important;
		color: $wh-a !important;
	}

	.resa-car .column.price-wp .btn-submit:focus,
	.resa-car .column.price-wp .btn-submit:hover {
		background-color: shade($bk-a, 5%) !important;
		color: $wh-a !important;
	}

	.resa-car .column.price-wp .btn-submit.green:focus,
	.resa-car .column.price-wp .btn-submit.green:hover {
		background-color: shade($bk-a, 10%) !important;
		color: $wh-a !important;
	}

	.resa-car .column.price-wp .txt-small {
		font-size: 10px;
		display: block;
		line-height: 1.2em;
		font-weight: 600;
	}

	.resa-car .column.content-wp {
		vertical-align: top;
		padding: 20px 30px;
	}

	.resa-car .column.content-wp .title {
		font-size: px(24);
		font-family: 'museo500', sans-serif;
		color: $bl-c;
		margin-bottom: 5px;
	}

	.resa-car .column.content-wp .title .similar {
		color: shade($gy-a, 60%);
		font-size: px(12);
		font-weight: 400;
		font-style: italic;
	}

	.resa-car .column.content-wp .category {
		display: inline-block;
		line-height: 1em;
		font-size: px(11);
		font-weight: 300;
		background-color: $gy-a;
		padding: 5px 10px;
		border-radius: 15px;
	}

	.resa-car .txt-presentation {
		font-size: px(13);
		line-height: 1.4em;
		font-weight: 300;
		margin-top: 10px;
		display: block;
	}

	.resa-car .option-wp {
		margin-top: 15px;
	}

	.resa-car .option-wp .item-sub {
		float: left;
		width: 40%;
		margin-bottom: 2px;
	}

	.resa-car .option-wp .txt {
		font-size: px(12);
	}

	.resa-car .option-wp .sprite,
	.resa-car .option-wp .txt {
		vertical-align: middle;
	}

	.reglementation-button {
    display: block;
		text-decoration: underline;
		margin-top: 10px;
		font-size: 11px;
		color: $bl-c;
		font-family: 'museo500', sans-serif;
	}

	.resa-car .option-wp .sprite {
		margin-right: 5px;
	}

	.resa-car .price-title {
		font-size: px(12);
		font-weight: 600;
		display: block;
		line-height: 1em;
		margin-bottom: 7px;
		text-transform: uppercase;
	}

	.resa-car .column.price-wp .bloc {
		padding: 15px 30px;
		height: 100%;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
		// height: 100%;
		// width: 50%;
		// display: flex;
		// flex-flow: column wrap;
		// justify-content: space-between;
		// display: flex;
		// flex-flow: column wrap;
		// align-items: flex-start;
		// justify-content: center;
	}

	@include media-breakpoint-down(md) {
		.item {
			margin-bottom: 40px;
		}

		.resa-car .column.price-wp .price-list .small {
			display: inline-block;
		}

		.resa-car .unavailable-date,
		.resa-car .unavailable-txt {
			display: inline-block;
			margin: 0;
		}

		.resa-car.unavailable {
			padding-bottom: 94px;
		}

		.resa-car.unavailable:after {
			width: calc(100% + 0px);
			height: calc(100% - 93px);
		}

		.resa-car.unavailable .column.price-wp .bloc.left {
			justify-content: center;
		}

		.resa-car {
			padding-bottom: 126px;
		}

		.resa-car .column.img-wp {
			width: 270px;
		}

		.resa-car .column.img-wp .img-fluid {
			width: 270px;
		}

		.resa-car .column.price-wp {
			background-color: tint($gy-a, 50%);
			height: auto !important;
			position: absolute;
			bottom: 0;
			border-radius: 0;
			left: 0;
			display: flex;
			padding: 0;
			border-left: 0;
			border-radius: 0 0 10px 10px;
			width: 100%;
			flex-flow: row nowrap;
			border-top: 1px solid shade($gy-a,5%);
		}

		.resa-car .column.price-wp .bloc {
			width: 100%;
			display: block;
		}

		.resa-car .column.price-wp .bloc.left {
			order: 1;
			border-bottom: 0;
		}

		.resa-car.except-parc {
			padding-bottom: 131px;
		}

		.resa-car.except-parc .column.price-wp .bloc {
			width: 100%;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
		}

		.resa-car.except-parc .column.price-wp .total br {
			display: none;
		}

		.resa-car.except-parc .column.price-wp .btn-submit {
			width: 275px;
		}

		.resa-car.except-parc .column.price-wp .total:before {
			top: 10px !important;
		}
	}
	@include media-breakpoint-down(sm) {
		.resa-car.unavailable {
			padding-bottom: 0;
		}

		.resa-car.unavailable:after {
			height: calc(100% - 90px) !important;
		}

		.resa-car .unavailable-date,
		.resa-car .unavailable-txt {
			text-align: left;
		}

		.promo {
			top: 5px;
			right: 5px;
			transform: rotate(0) scale(.75);
		}

		.resa-car.unavailable:after {
			width: calc(100% + 0px);
			height: calc(100% - 74px);
		}

		.resa-car-wp .resa-car .column.content-wp {
			padding-top: 10px;
		}

		.resa-car .column.img-wp .img-fluid {
			width: 70%;
			margin: 20px auto;
			display: block;
		}

		.item {
			margin-bottom: 20px;
		}

		.resa-car {
			display: block;
			padding-bottom: 0;
		}

		.resa-car .column.img-wp {
			padding-bottom: 10px;
			padding-top: 10px;
		}

		.resa-car .column {
			display: block;
			width: 100% !important;
			padding-left: 20px !important;
			padding-right: 20px !important;
			border-right: none !important;
		}

		.resa-car .column.content-wp {
			border-top: 1px solid shade($gy-a, 10%);
			border-bottom: 1px solid shade($gy-a, 10%);
		}

		.resa-car .column.price-wp {
			position: relative;
			bottom: auto;
			left: auto;
			padding: 0 !important;
			border-top: none !important;
		}

		.resa-car .column.price-wp .btn-submit,
		.resa-car .column.price-wp .price-list {
			display: block;
			float: none;
			width: 100%;
			margin-top: 0;
			border: none !important;
		}

		.resa-car .column.price-wp .price-list {
			padding: 0;
			margin-top: 5px;
		}

		.resa-car .column.price-wp .btn-submit {
			margin-bottom: 0;
		}

		.resa-car .column.price-wp .bloc {
			padding: 20px;
		}

		.resa-car.except-parc {
			padding-bottom: 0;
		}
	}
	@include media-breakpoint-down(xs) {
		.resa-car.unavailable:after {
			width: calc(100% + 0px);
			height: calc(100% - 91px);
		}

		.resa-car .unavailable-date,
		.resa-car .unavailable-txt {
			text-align: center;
		}

		.resa-car .unavailable-date {
			margin-top: px(10);
		}

		.resa-car .column.content-wp .title {
			font-size: px(22);
		}

		.resa-car .column.img-wp {
			width: 100%;
		}

		.resa-car .column.img-wp .img-fluid {
			width: 100%;
			margin: 10px auto;
		}

		.resa-car .column.price-wp {
			width: 100%;
			flex-flow: column wrap;
		}

		.resa-car .column.price-wp .bloc {
			width: 100%;
			height: auto;
		}

		.resa-car .column.price-wp .bloc.left {
			order: 0;
			border-left: none;
		}

		.resa-car .column.price-wp .bloc {
			padding: 7px 20px 15px;
		}

		.resa-car.except-parc .column.price-wp .bloc {
			width: 100%;
			flex-flow: column wrap;
			justify-content: space-between;
			align-items: center;
		}

		.resa-car.except-parc .column.price-wp .btn-submit {
			width: 100%;
		}
	}
}

// Step 2
.resa-step-2 {
	.item {
		border: 1px solid $gy-a;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		width: 100%;
		color: $rd-a;
		height: 75px;
		font-size: px(14);
		margin-bottom: 10px;
		display: table;
		background-color: tint($gy-a, 60%);
		box-shadow: 0 1px 1px rgba(0, 0, 0, .1);

		&.price-off {
			@include media-breakpoint-down(xs) {
				padding-bottom: 8px !important;

				&:after {
					display: none !important;
				}
			}
		}

		&.total {
			margin-top: px(30);
			background-color: $bl-c;
			border-bottom: 1px solid $gy-a;
			padding: px(8);
			color: $bl-c;

			&:after {
				display: none !important;
			}

			.title {
				color: $wh-a;
				text-transform: uppercase;
				font-weight: 700;
				font-family: 'museo500', sans-serif;
				padding-left: px(10);
			}

			.col-option-2 {
				border-radius: 5px;
				border: none;
				font-size: px(20);
				font-family: 'museo500', sans-serif;
				background-color: $wh-a;
				font-weight: 700;

				&:before {
					display: none;
				}
			}
			@include media-breakpoint-down(xs) {
				margin-top: px(20);

				.col-option-1 {
					padding-left: 0 !important;

					.title {
						font-size: px(15);
					}
				}

				.col-option-2 {
					width: 110px !important;
					height: 57px !important;
					padding-top: px(0);

					.price {
						font-size: 18px;
					}
				}
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		.more-info-trigger {
			position: absolute;
			top: 50%;
			right: 10px;
			z-index: 2;
			margin-top: -9px;
			color: $bk-a;

			.material-icons {
				font-size: px(18);
			}
		}

		.col-option-1,
		.col-option-2 {
			display: table-cell;
			padding: 0 15px;
			vertical-align: middle;
		}

		.col-option-1 {
			padding: 8px 35px 8px 8px;
			font-weight: 700;
		}

		.checkboxradio-wp {
			display: table;
			width: 100%;

			> input[type=checkbox] + label {
				font-size: px(14.5);
				font-family: 'museo500', sans-serif;
				height: 56px;
				width: 100%;
				display: table-cell;
				font-weight: 700;
				color: shade($gy-a, 70%);
				line-height: 1.15em;
				background-image: url('../images/icons/sprite-checkbox_radio-step2_3.png');
				margin-bottom: 0;
				vertical-align: middle;
				padding: 0 0 0 70px;
				background-size: 56px;
				em {
					display: block;
					font-weight: 300;
					font-size: 85%;
					color: shade($gy-a, 70%);
					margin-top: px(3);
				}
				@include media-breakpoint-down(lg) {
					font-size: px(13);
				}
				@include media-breakpoint-down(sm) {
					font-size: px(15);
				}
				@include media-breakpoint-down(xs) {
					font-size: px(14);
				}
			}

			> input[type=checkbox] + label {
				background-position: 0 bottom;
			}

			> input[type=checkbox]:checked + label {
				background-position: 0 top;
				color: $bl-c;
			}
		}
	}

  .quantite, .choix {
    padding: 0 0 0 70px;

    .selectpicker-wp {
      display: inline-block;
      position: relative;

      /*.bootstrap-select .btn.dropdown-toggle {
        border-radius: 6px;
        padding: 5px 10px !important;
        border: $wh-a !important;
        background-color: $wh-a;

        .filter-option-inner-inner {
          font-size: px(14);
          text-align: center;
        }
      }*/

      .bootstrap-select .btn.dropdown-toggle {
        line-height: 1em;
        display: block !important;
        height: 25px;
        width: 56px;
        border-radius: 6px;
        padding: 5px 10px !important;
        border: $wh-a !important;
        background-color: $wh-a;
        &:after {
          right: 2px;
        }

        .filter-option-inner-inner {
          font-size: px(14);
          text-align: center;
        }
      }



    }
    .label {
      color: shade($gy-a, 60%);
      font-weight: normal;
      padding-right: 10px;
    }
    &.disabled .selectpicker-wp .bootstrap-select .btn.dropdown-toggle,
    &.disabled .label {
      opacity: 0.4;
    }
  }

	/*.selectpicker-wp {
		position: absolute;
		top: 9px;
		left: 8px;
		display: block;
		height: 56px;
		width: 56px;
		vertical-align: middle;
		.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
			width: 100%;
		}
		&.inactive {
			display: none;
		}

		.bootstrap-select .btn.dropdown-toggle {
			line-height: 1em;
			display: block !important;
			height: 56px;
			width: 56px;
			border-radius: 6px;
			padding-left: 12px !important;
			//padding: 22px 20px 10px 10px !important;
			border: $wh-a !important;
			background-color: $wh-a;
			&:after {
				right: 8px;
				transform: scale(1) translateX(0);
			}

			.filter-option-inner-inner {
				font-size: px(16);
				font-family: 'museo500', sans-serif;
				text-align: center;
			}
		}
	}*/
}

.resa-included {
	border-bottom: 1px solid shade($gy-a, 10%);
	padding: 15px;
	background-color: $gy-a;
	border-radius: 8px;

	.item {
		line-height: 1.2em;
		position: relative;
		padding: px(3) 0 0 px(25);
		margin-bottom: px(5);
		font-size: px(14);
		font-weight: 600;
		color: $bl-c;
	}

	.item .material-icons {
		content: '';
		position: absolute;
		color: $bl-c;
		top: px(3);
		left: 0;
		font-size: px(18);
	}
}

.btn-payment {
	position: relative;
	background-color: $rd-a;
	color: $wh-a;
	border-radius: 10px;
	overflow: hidden;
	text-transform: uppercase;
	padding: px(10) px(20) px(10) px(75);

	.icon-left {
		position: absolute;
		height: 100%;
		width: 50px;
		padding-top: px(12);
		left: 0;
		top: 0;
		background-color: rgba(0,0,0,0.1);
	}

	.icon-right,
	.txt {
		display: inline-block;
		vertical-align: middle;
	}

	.icon-right {
		position: relative;
		top: 3px;
	}
}

.resa-aside-wp {
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	padding-right: 0;

	.header {
		background-color: shade($gy-a, 50%);
		position: relative;
		color: $wh-a;
		padding: px(13) px(70) px(13) px(15);

		.category {
			text-transform: uppercase;
			font-size: px(10);
			letter-spacing: .1em;
			font-weight: 300;
		}

		.title {
			margin-top: px(3);
			font-size: px(17);
			font-weight: 400;
			line-height: 1.1em;

			em {
				font-size: 80%;
				font-weight: 300;
			}
		}

		.btn-edition {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: px(55);
			background-color: rgba(0,0,0,0.15);
			color: $wh-a;
			padding-top: px(7);

			.material-icons {
				font-size: px(26);
			}
		}
	}

	.car-wp {
		padding-bottom: px(15);

		.icons-wp {
			padding: 0 px(20);

			.txt {
				font-size: px(10);
				display: block;
				line-height: 1.1em;
			}

			.car-icon {
				position: relative;
				padding: px(5) 0 px(5) px(17);

				.sprite {
					position: absolute;
					top: px(-1);
					left: px(-5);
				}
			}
		}
	}

	.options-wp {
		padding: px(15) px(20) px(10);
		background-color: tint($gy-a, 60%);

		.item {
			position: relative;
			padding: px(9) px(80) px(9) 0;
			font-size: px(13);
			color: shade($gy-a, 70%);
			line-height: 1.2em;
			&.total {
				color: $bl-c !important;
				padding-top: 15px !important;
				font-size: 15px;
				.price {
					top: 15px !important;
					font-size: 15px !important;
				}
			}

			&.white-bg {
				background-color: $gy-a;
				padding-left: 10px;
				padding-right: 10px;
				border-radius: 5px;
				border: 0 !important;
				border-bottom: 1px solid shade($gy-a, 20%) !important;
				margin-bottom: 3px;
				.price {
					right: 10px;
				}
			}

			&.arrival-departure {
				border-radius: 5px;
				border: 1px solid shade($gy-a, 20%);
				background-color: $wh-a;
				position: relative;
				padding-left: 32px;
				margin-bottom: 10px;
				font-size: 12px;
				line-height: 1.4em;
				strong {
					font-family: 'museo500', sans-serif;
					color: $bl-c;
				}
				.material-icons {
					position: absolute;
					left: 5px;
					top: 50%;
					color: shade($gy-a, 50%);
					margin-top: -12px;

					&.except-calendar {
						margin-top: -9px;
						left: 7px;
						font-size: 18px;
					}
				}
			}

			.price {
				position: absolute;
				top: px(9);
				right: 0;
			}

			&.promo {
				position: relative;
				padding: 5px;
				background-color: rgba(0,0,0,0.05);
				border: 1px solid shade($gy-a, 75%);
				margin-bottom: px(10);
				line-height: 1.2em;

				.btn-promo {
					text-align: left;
					position: relative;
					display: block;
					width: 100%;
					font-style: italic;
					font-size: px(12);
					padding-left: px(20);

					.material-icons {
						position: absolute;
						top: 50%;
						left: 0;
						margin-top: -8px;
						font-size: px(15);
					}
				}
			}

			&.except {
				font-family: 'museo500', sans-serif;
				color: tint($bk-a, 20%);
				text-transform: uppercase;

				.price {
					font-family: 'museo500', sans-serif;
					font-size: 14px;
				}
			}
		}

		.item {
			&.border-bottom {
				border-bottom-color: shade($gy-a, 20%) !important;
			}
			//border-top: 1px solid shade($gy-a, 20%);
		}

		&.step-3 {
			.price {
				font-weight: 400 !important;
				text-decoration: underline;
				text-transform: none;
				font-size: px(11);
			}

			.item:not(.except) {
				padding-right: 0;

				em {
					font-size: 95%;
					font-style: italic;
				}
			}
		}

		.txt-info {
			padding: px(9) 0 0;
			font-style: italic;
			border-top: 1px solid shade($gy-a, 20%);
			line-height: 1.2em;
			color: shade($gy-a, 65%);
			font-size: px(12);
		}
	}

	.btn-wp {
		display: block;
		width: 100%;
		text-transform: uppercase;
		font-size: px(13);
		padding: px(13);
		font-weight: 600;
		background-color: shade($gy-a, 50%);
		color: $wh-a;
		text-align: center;
		letter-spacing: .05em;

		.material-icons {
			font-size: 15px;
		}
		> * {
			display: inline-block;
			vertical-align: middle;
		}
	}
	@include media-breakpoint-down(md) {
		.header {
			padding-top: px(9);
			padding-bottom: px(9);

			.title {
				margin-top: 0;
			}
		}

		.content-wp {
			position: relative;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			border: 1px solid $gy-a;
			border-radius: 0 0 8px 8px;

			.car-wp {
				flex: 0 0 45%;
				max-width: 45%;
				padding: 0 px(15) px(10);
			}

			.options-wp {
				flex: 0 0 55%;
				max-width: 55%;
				position: relative;
				z-index: 2;
				padding: px(10) px(25);

				.item {
					.price {
						top: px(9);
					}

				}
			}

			&:after {
				content: '';
				width: 55%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				background-color: $gy-a;
			}
		}

		.btn-wp {
			display: block;
			width: 240px;
			margin: px(15) auto 0;
			border-radius: 8px;
			padding-top: px(8);
			padding-bottom: px(8);
			font-size: px(14);
		}
	}
	@include media-breakpoint-down(xs) {
		.content-wp {
			display: block;

			.car-wp,
			.options-wp {
				display: block;
				width: 100%;
				max-width: 100%;
			}

			&:after {
				display: none;
			}
		}
	}
}

.btn-cgv {
	width: 100%;
	color: shade($gy-a, 50%);
	text-align: center;
	display: block;
	padding: .71429rem 0;
	font-size: .85714rem;
	text-decoration: underline;
}


.title-wp {
  position: relative;
  display: inline-block;
  padding-right: 30px;

  .more-info-trigger {
    top: px(4);
    right: 0;
    position: absolute;

    .material-icons {
      font-size: px(18);
    }
  }
}

.payment-planning {
	padding-left: 35px;
	.item {
		padding: 10px 15px 5px;
		display: inline-block;
		background-color: $wh-a;
		border-radius: 5px;
		border: 1px solid $gy-a;
		text-align: center;
		margin-right: 10px;
		font-size: 12px;
		color: shade($gy-a, 40%);
		@include media-breakpoint-down(xs) {
			display: block;
			margin-right: 0;
			margin-bottom: 15px;
		}

		.title {
			font-family: 'museo500', sans-serif;
		}
		.price {
			font-family: 'museo500', sans-serif;
			color: $bl-c;
			font-size: 14px;
			margin-top: 5px;
		}
	}
}

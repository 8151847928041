// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
@include media-breakpoint-down(xs) {
	#nav-mobile-trigger {
		position: absolute;
		top: 30px;
		right: 15px;
	}
}

// Header :: Structure

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	transition: all .2s ease-in-out;

	.container {
		position: relative;
		display: flex;
		padding-top: 10px;
		@include media-breakpoint-down(sm) {
			display: block;
		}

		.col-left {
			width: 200px;
			.logo {
				display: block;
				position: relative;
				z-index: 3;
			}
			.tel {
				display: block;
				position: relative;
				text-align: center;
				padding-top: 10px;
				transition: all .1s ease-in-out;
				&:hover {
					transform: translateY(5px);
				}
				span {
					font-weight: 700;
					color: $wh-a;
					font-size: 16px;
					position: relative;
					z-index: 2;
				}

				&:after {
					content: '';
					z-index: 1;
					position: absolute;
					width: calc(100% - 20px);
					left: 10px;
					top: -30px;
					background-color: $rd-a;
					padding: 40px 30px;
					border-radius: 0 0 20px 20px;
					transform: rotate(6deg);
				}
			}
			@include media-breakpoint-down(lg) {
				width: 175px;
			}
			@include media-breakpoint-down(md) {
				width: 140px;
				.tel {
					padding-top: 7.5px;
					&:after {
						top: -40px;
					}
					span {
						font-size: 14px;
					}
				}
			}
			@include media-breakpoint-down(sm) {
				width: 120px;
				.tel {
					background-color: $rd-a;
					border-radius: 20px;
					padding: 31px 20px 8px 20px;
					position: absolute;
					top: -22px;
					right: 180px;

					&:after {
						display: none;
					}
				}
			}
			@include media-breakpoint-down(xs) {
				.tel {
					padding: 10px 25px;
					line-height: 1em;
					top: 22px;
					right: 80px;
				}
			}

		}
		.col-right {
			padding-top: 40px;
			padding-left: 30px;
			width: calc(100% - 200px);
			@include media-breakpoint-down(lg) {
				width: calc(100% - 175px);
				padding-left: 40px;
			}
			@include media-breakpoint-down(md) {
				padding-top: 49px;
				padding-left: 0;
				width: calc(100% - 140px);
			}
			@include media-breakpoint-down(sm) {
				margin-top: 15px;
				justify-content: center;
				background-color: tint($gy-a, 70%);
				padding: 10px;
				width: 100%;
				border-radius: 10px;
				border: 1px solid $gy-a;
			}
			@include media-breakpoint-down(xs) {
				display: none;
			}

		}
		.site-redirect {
			background-color: $bl-c;
			justify-content: center;
			color: $wh-a;
			display: flex;
			align-items: center;
			height: 50px;
			font-size: 14px;
			border-radius: 0 0 20px 20px;
			width: 150px;
			position: absolute;
			top: -10px;
			padding-top: 10px;
			right: -20px;
			transition: all .1s ease-in-out;
			&:hover {
				transform: translateY(5px);
			}
			@include media-breakpoint-down(md) {
				right: 15px;
			}
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
	}
}

#nav-main {
	display: flex;

	.item {
		padding-left: 20px;
		&.except {
			padding-left: 35px;
			.link {
				border-color: $bl-b;
				color: $bl-b;
			}
		}
	}

	.link {
		display: block;
		border: 2px solid $gy-a;
		font-size: 14px;
		background-color: $wh-a;
		line-height: 0;
		padding: 15px;
		border-radius: 30px;
		font-family: 'museo500', sans-serif;
		transition: all .2s ease-in-out;
	}

	.link:focus,
	.link:hover {
		background-color: $bl-b;
		color: $wh-a !important;
		border-color: $bl-b;
	}

	.link.active {
		background-color: $bl-b;
		color: $wh-a !important;
		border-color: $bl-b;
	}

	@include media-breakpoint-down(lg) {
		.item {
			padding-left: 15px;
			&.except {
				padding-left: 15px;
			}
		}
		.link {
			font-size: 13px;
		}
	}
	@include media-breakpoint-down(md) {
		justify-content: flex-end;
		.item {
			padding-left: 10px !important;
		}
		.link {
			padding: 15px 10px;
			font-size: 12px;
		}
	}
	@include media-breakpoint-down(sm) {
		justify-content: center;
		.item {
			padding: 0 5px !important;
		}
		.link {
			border-width: 1px;
			padding: 15px 10px;
			border-radius: 12.5px;
			letter-spacing: -0.025em;
			font-size: 11px;
		}
	}
	@include media-breakpoint-down(xs) {
		display: block;
		padding: 10px;

		.item {
			&:not(:last-child){
				margin-bottom: 10px;
			}
		}
		.link {
			text-align: center;
			font-size: 14px;
			padding: 17.5px 15px;
			border-radius: 30px;
		}
	}
}

#resa-header {
  position: fixed;
	top: 0;
  z-index: 10;
  width: 100%;
  background-color: $wh-a;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  transition: all .2s ease-in-out;
  padding: 5px 0;

  .logo {
    position: relative;
    display: block;
    padding: 0;
  }

  .logo .img-fluid {
    display: block;
		width: 90px;
    margin: 0 auto;
  }

  .logo:after {
    content: '';
    position: absolute;
    top: -10px;
    right: -30px;
    height: calc(100% + 20px);
    width: 1px;
    background-color: $gy-a;
  }

  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: shade($gy-a, 20%);
  }

  .item .txt {
    font-size: px(16);
		font-family: 'museo500', sans-serif;
  }

  .item .number {
    font-size: px(18);
    line-height: 1em;
    position: relative;
    top: -1px;
    font-weight: 400;
    margin-right: 5px;
  }

  .item .number:after {
    content: '.';
  }

  .item:not(.first):before {
    content: '';
    position: absolute;
    top: 50%;
    left: -15px;
    height: 14px;
    margin-top: -7px;
    width: 1px;
    background-color: $gy-a;
  }

  .item.active {
    color: $rd-a;
  }

  .item.active .number:after {
    display: none;
  }

  .item.active .number {
    background-color: $rd-a;
    margin-right: 10px;
    font-size: px(14);
    padding-top: 5px;
    color: $wh-a;
    text-align: center;
    border-radius: 25px;
    width: 25px;
    height: 25px;
  }

  .item.active .txt {
    font-weight: 700;
  }
  @include media-breakpoint-down(md) {
    padding: px(10) 0;

    .logo {
      img {
        margin: 0 !important;
      }
    }

    .logo:after {
      right: -15px;
    }

    .item .txt {
      font-size: px(15);
    }

    .item .number {
      font-size: px(16);
    }

    .item.active .number {
      font-size: px(13);
      width: 20px;
      height: 20px;
      padding-top: 3px;
    }
  }
  @include media-breakpoint-down(sm) {
		padding: 3px 0;
    .logo:after {
      right: 5px;
    }

    .row {
      align-items: flex-start !important;
    }

    .logo .img-fluid {
      display: block;
      width: 70px;
      margin: 0 0 0 15px !important;
    }

    .logo:after {
      right: -8px;
    }

    .item {
      flex-flow: column wrap;
      padding-top: 5px;
    }

    .item .number {
      margin-right: 0 !important;
    }

    .item .number:after {
      display: none;
    }

    .item .txt {
      font-size: px(13);
    }

    .item.active .number {
      font-size: px(11.5);
      width: 17px;
      line-height: 1.1em;
      height: 17px;
      padding-top: 2px;
    }

    .item.active .txt {
      margin-top: 3px;
    }

    .item:not(.active) .txt {
      padding-top: 3px;
    }
  }
  @include media-breakpoint-down(xs) {
    padding: px(5) 0;


		.logo .img-fluid {
      margin: 0 !important;
    }

    .col-4 {
      flex: 0 0 120px;
      max-width: 120px;
    }

    .col-8 {
      flex: 0 0 calc(100% - 120px);
      max-width: calc(100% - 120px);
    }

    .row {
      align-items: center !important;
    }

    .item {
      padding-top: 0;
    }

    .item .txt {
      display: none;
    }

    .item.active .number {
      width: 20px;
      height: 20px;
      padding-top: 4px;
      font-size: px(12);
    }
  }
}

#resa-summary-wp {
	margin-top: 63px;
	padding: 30px 0;
	background-image: url('../images/illustrations/bg-resa.png');
	background-color: $bl-a;
	background-size: cover;
	background-position: center center;
	@include media-breakpoint-down(md) {
		margin-top: 68px;
	}
	@include media-breakpoint-down(sm) {
		margin-top: 51px;
		.container {
			max-width: 100%;
		}
	}
	@include media-breakpoint-down(xs) {
		padding: 15px 0;
	}
}

#resa-summary {
  position: relative;
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: $wh-a;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);

  .column {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 41%;
    padding: 20px 15px 20px 0;

    &:not(.except):after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: $gy-a;
      right: px(20);
      top: 0;
    }
  }

  .column.except {
    width: 18%;
  }

  .column.except .list {
    border-right: none;
  }

  .column:first-child .list {
    padding-left: 50px;
  }

  .column:first-child .list:after {
    left: 20px;
  }

  .list {
    padding: 0 15px 0 40px;
    position: relative;
    height: 100%;
  }

  .column .material-icons {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -10px;
		color: $bl-c;
  }

  .column.except-bis .material-icons {
    left: 5px;
    color: $bl-c;
  }

  .column.except .list:after {
    display: none;
  }

  .column.except .list {
    padding-left: 40px;
  }

  .column.except .img {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 5px;
    font-size: px(18);
  }

  .place {
    text-transform: uppercase;
    display: block;
    line-height: 1.2em;
    font-weight: 600;
    color: $bl-c;
    font-size: px(14);
		font-family: 'museo500', sans-serif;
  }

  .date {
    color: shade($gy-a, 40%);
    font-weight: 400;
    font-size: px(12);
    display: block;
    line-height: 1.2em;
    margin-top: 5px;
  }

  .btn-submit {
    width: 130px;
    height: 84px;
    background-color: $rd-a;
    color: $wh-a;

    &.open {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }

  .btn-submit > * {
    display: inline-block;
    vertical-align: middle;
  }
  @include media-breakpoint-down(lg) {
    .column:not(.except):after {
      right: 15px;
    }
  }
  @include media-breakpoint-down(md) {
    .column {
      padding: 15px 15px 15px 0;
    }

    .place {
      font-size: 12px;
    }

    .column:not(.except):after {
      right: 5px;
    }

    .btn-submit {
      height: 81px;
      width: 120px;
    }
  }
  @include media-breakpoint-down(sm) {
    .place {
      font-size: px(12);
    }

    .date {
      font-size: px(11);
      margin-top: px(2);
    }

    .column {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .column.except .img {
      display: none !important;
    }

    .column .list {
      padding-left: 10px !important;
    }

    .column .material-icons {
      display: none !important;
    }

    .btn-submit {
      height: 70px;
      font-size: px(13);
      width: 90px;

      .material-icons {
        font-size: px(18);
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .column.except .img {
      left: 8px !important;
      font-size: px(17) !important;
      display: block !important;
    }
		.place {
			font-size: px(13);
		}

		.date {
			font-size: px(12);
		}

    .column:after {
      display: none !important;
    }

    .column .material-icons {
      left: 7px !important;
      font-size: px(22) !important;
      display: block !important;
    }

    .column,
    .column .list {
      display: block;
      width: 100% !important;
    }

    .column .list {
      border-right: none !important;
      padding-left: 32px !important;
    }

    .column {
      border-bottom: 1px solid $gy-a;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .column.except {
      border-bottom: none !important;
    }

    .list:after {
      left: 10px !important;
    }

    .column.except .list:after {
      left: 10px !important;
    }

    .btn-submit {
      height: 35px;
      width: 100%;
    }
  }
}

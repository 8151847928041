// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #000000;
$wh-a: #FFFFFF;
$gy-a: #E5E5E5;
$gy-d: #666;
$bl-a: #C7DDFF;
$bl-b: #0063FF;
$bl-c: #002052;
$rd-a: #ED4011;
$ye-a: #FFFB5E;
$gn-a: #77BA3F;

// Color
.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}

.c-gy-a {
  color: $gy-a;
}

.c-gy-d {
  color: $gy-d;
}

.c-bl-a {
  color: $bl-a;
}

.c-bl-b {
  color: $bl-b;
}

.c-bl-c {
  color: $bl-c;
}

.c-rd-a {
  color: $rd-a;
}

.c-ye-a {
  color: $ye-a;
}

.c-gn-a {
  color: $gn-a;
}

// Background
.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-bl-a {
  background-color: $bl-a;
}

.bg-bl-b {
  background-color: $bl-b;
}

.bg-bl-c {
  background-color: $bl-c;
}

.bg-rd-a {
  background-color: $rd-a;
}

.bg-ye-a {
  background-color: $ye-a;
}

.bg-gn-a {
  background-color: $gn-a;
}

// General :: Remove autofill (Chrome)

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border: none !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
	transition: background-color 5000s ease-in-out 0s;
}
// Input :: Text

.form-control {
	color: $bk-a !important;
	border-color: $gy-a;
	box-shadow: none;
	padding: 0;
	border-radius: 0;
	-webkit-appearance: none;
	height: 40px;
	padding: 0 10px;
	-moz-appearance: none;

	&:focus {
		border-color: shade($gy-a, 15%);
		box-shadow: none;
		color: $bk-a;
		background: $wh-a;
	}
}
// Input :: Text Placeholder

.form-control.placeholder::placeholder {
	color: $bk-a;
}
// Input :: Checkbox & Radio

.checkboxradio-wp {
	position: relative;
	display: block;

	> input,
	> label {
		cursor: pointer;
	}

	> input {
		position: absolute;
		-moz-opacity: 0;
		opacity: 0;
		filter: alpha(opacity = 0);
	}

	> input[type=radio] + label,
	> input[type=checkbox] + label {
		display: block;
		padding: 0 0 0 35px;
		font-size: rem(13);
		font-weight: 300;
		color: $bk-a;
		background-image: url('../images/icons/sprite-checkbox_radio.png');
		line-height: 1.3em;
		background-repeat: no-repeat;
		background-size: 17px;
	}

	> input[type=radio] + label {
		background-position: 0 -168px;
	}

	> input[type=checkbox] + label {
		background-position: 0 -537px;
	}

	> input[type=radio]:checked + label {
		background-position: 0 0;
	}

	> input[type=checkbox]:checked + label {
		background-position: 0 -352px;
	}
}
// Input :: Radio ON/OFF

.btn-switch-wp {
	position: relative;
	display: flex;
	align-items: center;

	.label {
		padding-left: 15px;
		color: $bk-a;
		font-weight: 400;
		cursor: pointer;
	}

	.checkbox-toggle {
		position: absolute;
		margin-left: -9999px;
		visibility: hidden;
	}

	.checkbox-toggle + label {
		display: block;
		position: relative;
		cursor: pointer;
		outline: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.checkbox-toggle-round + label {
		margin: 0;
		padding: 2px;
		width: 55px;
		height: 31px;
		background-color: $bk-a;
		border-radius: 60px;
		transition: background .2s;
	}

	.checkbox-toggle-round + label:after,
	.checkbox-toggle-round + label:before {
		display: block;
		position: absolute;
		content: '';
	}

	.checkbox-toggle-round + label:after {
		top: 4px;
		left: 4px;
		bottom: null;
		width: 23px;
		height: 23px;
		background-color: $wh-a;
		border-radius: 52px;
		transition: margin .2s, background .2s;
	}

	.checkbox-toggle-round:checked + label {
		background-color: tint($bk-a,50%);
	}

	.checkbox-toggle-round:checked + label:after {
		margin-left: 23px;
	}
}
// Input :: Range

.range-wp {
	position: relative;
	padding: 0 px(10) px(30);

	.value-wp {
		display: flex;
		justify-content: space-between;
		width: 100%;
		position: absolute;
		top: px(30);
		left: 0;
	}

	.value {
		color: $bk-a;
	}

	.value.max {
		text-align: right;
	}

	.slider.slider-horizontal {
		width: 100%;
	}

	.slider-handle {
		background: $bk-a;
	}

	.slider-track {
		width: calc(100% + 20px) !important;
		left: -10px !important;
		background: tint($bk-a,75%) !important;
	}

	.slider-selection {
		background: tint($bk-a, 90%) !important;
	}
}
// Input :: Datepicker

.datepicker-wp {
	position: relative;

	.form-control {
		padding-right: px(30);
	}

	.ui-datepicker-trigger {
		position: absolute;
		top: 50%;
		right: 10px;
		margin-top: -12px;

		.material-icons {
			font-size: px(18);
		}
	}
}

.ui-datepicker {
	font-size: px(14);
	background-image: none !important;
	border-radius: 0 !important;

	.ui-widget-header {
		background: $bl-a;
		border-radius: 0 !important;
		border: none !important;
	}

	.ui-button,
	.ui-state-default,
	.ui-widget-content .ui-state-default,
	.ui-widget-header .ui-state-default {
		border: none;
		background: $bl-a;
	}

	.ui-datepicker-today .ui-state-highlight {
		border: none;
		background: $bl-b;
		color: $wh-a;
	}

	.ui-button.ui-state-active:hover,
	.ui-button:active,
	.ui-state-active,
	.ui-widget-content .ui-state-active,
	.ui-widget-header .ui-state-active,
	a.ui-button:active {
		border: none;
		background: $bl-b;
		color: $wh-a;
	}

	.ui-state-default.ui-state-highlight.ui-state-hover {
		border: none !important;
		background: $bl-b !important;
		color: $wh-a !important;
	}

	.ui-button:focus,
	.ui-button:hover,
	.ui-state-focus,
	.ui-state-hover,
	.ui-widget-content .ui-state-focus,
	.ui-widget-content .ui-state-hover,
	.ui-widget-header .ui-state-focus,
	.ui-widget-header .ui-state-hover {
		background: transparent !important;
	}
}
// Input :: File

.custom-file-wp {
	position: relative;
	display: inline-block;
	width: 100%;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;

	.custom-file-input {
		position: relative;
		z-index: 2;
		width: 100%;
		height: calc(2.25rem + 2px);
		margin: 0;
		opacity: 0;
	}

	.custom-file-label {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1;
		height: calc(2.25rem + 2px);
		@extend .form-control;
	}

	.custom-file-label::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		display: block;
		height: calc(calc(2.25rem + 2px) - 1px * 2);
		padding: 0 px(15);
		background-color: $bk-a;
		color: $wh-a;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.custom-file-input:lang(fr)~.custom-file-label::after {
		content: 'Rechercher';
	}
}
// Input :: Select Structure

.selectpicker-wp {
	position: relative;

	.filter-option-inner {
		height: 100%;
		width: 100%;
		align-items: center;
		display: flex;
	}
	.bs-placeholder {
		.filter-option-inner-inner {
			opacity: .7;
		}
	}

	.bootstrap-select {
		width: 100% !important;
	}

	.filter-option {
		@include ellipsis;
	}

	.dropdown-toggle {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: px(30) !important;
		@extend .form-control;
		outline: none;
	}

	.dropdown-toggle:focus {
		outline: none !important;
	}

	.dropdown-toggle:after {
		@include position(absolute, 50% 10px null null);
		margin-top: -3px;
		color: $bk-a;
		transform: scale(1.5) translateX(-2.5px);
	}

	.bootstrap-select.show .dropdown-toggle:after {
		transform: rotate(-180deg);
	}
}
// Input :: Select Dropdown

.bootstrap-select.btn-group .dropdown-menu.inner {
	display: block;
}

.bootstrap-select.btn-group .dropdown-menu {
	padding: 0;

	li a,
	li a span.text {
		display: block;
	}

	li a {
		display: block;
		padding: px(5) px(15);
	}

	li a {
		outline: none !important;
	}

	li:not(:last-child) {
		border-bottom: 1px solid #f8f9fa;
	}

	li a:focus,
	li a:hover {
		background-color: #f8f9fa;
	}
}

/*.bootstrap-select .dropdown-menu .inner {
	max-height: 200px !important;
}*/

.bootstrap-select .dropdown-menu {
	min-width: 100% !important;
}
// Validation :: Error

.parsley-errors-list {
	color: #a94442;
	font-size: px(12);
	margin-top: px(5);
}

.custom-file-input.parsley-error + .custom-file-label,
.form-control.parsley-error {
	border-color: #a94442;
	color: #a94442 !important;
}

.form-control.parsley-error::placeholder {
	color: #a94442;
}

.section-form-wp {
	padding: 25px;
	background-color: $wh-a;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
	border-radius: 8px;

	.label-bis {
		font-size: px(13);
		color: $bl-c;
		font-family: 'museo500', sans-serif;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	.form-control-bis {
		display: block;
		width: 100%;
		border: none;
		border-bottom: 1px solid shade($gy-a, 10%);
		color: $bk-a;
		font-size: px(13);
		padding: 6px 0;
		outline: none !important;
		-webkit-appearance: none;
		border-radius: 0 !important;
	}

	.password {
		display: block;
		margin-top: px(8);
		font-size: px(12);
		font-style: italic;
		text-decoration: underline;
	}

	textarea.form-control-bis {
		margin-top: 5px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.dropdown.bootstrap-select {
		display: block;
		width: 100% !important;
		padding: px(6) 0;
		border-bottom: 1px solid shade($gy-a, 10%);

		.filter-option-inner {
			font-style: italic;
			color: shade($gy-a, 40%) !important;
			font-size: px(13);
		}

		&.changed {
			border-bottom-color: shade($gy-a, 20%);

			.filter-option-inner {
				font-style: normal;
				color: shade($gy-a, 20%) !important;
				font-size: px(13);
			}
		}
	}

	.form-control-bis.active,
	.form-control-bis:focus {
		border-bottom-color: shade($gy-a, 20%);
	}

	.form-control-bis::placeholder {
		color: shade($gy-a, 40%) !important;
		font-style: italic;
		opacity: 1;
		font-size: px(13);
	}
	@include media-breakpoint-down(sm) {
		.label-bis {
			font-size: px(13);
		}
		padding: 20px 20px 25px !important;
	}
}

.selectpicker-wp.select-category {
	position: relative;
	.bs-placeholder {
		.filter-option-inner-inner {
			opacity: 1;
		}
	}
	.form-control {
		border-radius: 30px;
		border: 2px solid $rd-a;
		font-size: 15px;
		.filter-option {
			color: $rd-a;
			padding-top: 2px;
			font-family: 'museo500', sans-serif;
			padding-left: 5px;
			.filter-option-inner-inner {
				max-width: 100%;
				text-overflow: ellipsis;
			}
		}
	}
	.dropdown-toggle:after {
		position: absolute;
		color: $rd-a;
		right: 10px;

		//@extend .sprite;
		//@extend .arrow-select-bis;
	}

}
